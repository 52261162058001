import { Pagination } from '@components/Pagination'
import { Table } from '@components/Table'

import { columns } from './column'
import { BadgeListTableProps } from './interface'

export function BadgeListTable({
  data,
  isLoading,
  page,
  perpage,
  total,
  pageChange,
  refetch,
  handleRemoveBadge,
}: BadgeListTableProps) {
  return (
    <div>
      <div className='flex items-center mb-[10px]'>
        <span className='text-secondary-100 text-[12px] font-light'>
          {`ข้อมูลทั้งหมด : ${total.toLocaleString()}`}
        </span>
      </div>
      <div className='flex-1 overflow-x-auto pb-[20px]'>
        <Table
          data={data}
          columns={columns({
            handleRemoveBadge: (bookId, id) => {
              refetch()
              handleRemoveBadge(bookId, id)
            },
          })}
          isLoading={isLoading}
          className='w-auto'
          headerClassName='border-x-[1px] border-y-[1px] border-gray text-secondary-100 text-[12px] font-bold'
        />
      </div>
      <div className='mt-[10px]'>
        <Pagination
          page={page}
          perpage={perpage}
          total={total}
          pageChange={pageChange}
        />
      </div>
    </div>
  )
}
