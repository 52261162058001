import cx from 'classnames'

import { Button } from '@components/Button'
import type { UploadBadgeButtonFieldProps } from './interface'

export function UploadBadgeButtonField({
  className,
  src,
  inputRef,
  onChange,
  showUploadDialog,
}: UploadBadgeButtonFieldProps) {
  return (
    <div className={cx('relative cursor-pointer', className)}>
      {src && (
        <Button
          onClick={showUploadDialog}
          type='button'
          className='bg-blue-2 font-mitr font-medium text-secondary-100 text-[14px] w-max px-[20px] py-[4px]'
        >
          อัปโหลดใหม่
        </Button>
      )}

      <input
        ref={inputRef}
        className='hidden'
        type='file'
        accept='image/*'
        onChange={onChange}
      />
    </div>
  )
}
