import type { QueryKey } from 'react-query'
import type { NavigateFunction } from 'react-router-dom'
import type { OptionType } from '@interfaces/OptionType'
import type { BadgeSearchFormType } from '@models/badge/BadgeSearchFormType'
import type { BadgeRequestType } from '@models/badge/BadgeRequestResponse'

export interface BadgeListPageProps {
  tabOption: OptionType[]
  tab: BookBadgeCategoryTypeEnum
  handleTabChange: (type: BookBadgeCategoryTypeEnum) => void
  searchParams: BadgeSearchFormType
  initialValues: BadgeSearchFormType
  page: number
  perpage: number
  handlePageChange: (newPage: number) => void
  navigate: NavigateFunction
  handleSubmitSearch: (value: BadgeSearchFormType) => void
  handleResetSearch: () => void
  queryKey: QueryKey
  data: BadgeRequestType[]
  total: number
  isLoading: boolean
  handleRemoveBadge: (bookId: number, badgeId: number) => void
  refetch: () => Promise<unknown>
}

export enum BookBadgeCategoryTypeEnum {
  ALL = 'ALL',
  EXCLUSIVE = 'EXCLUSIVE',
  MOVIES_SERIES = 'MOVIES_SERIES',
  BESTSELLER = 'BESTSELLER',
  AWARD = 'AWARD',
}

export const bookBadgeCateoryName = {
  [BookBadgeCategoryTypeEnum.ALL]: 'All',
  [BookBadgeCategoryTypeEnum.AWARD]: 'Award',
  [BookBadgeCategoryTypeEnum.BESTSELLER]: 'Bestseller',
  [BookBadgeCategoryTypeEnum.MOVIES_SERIES]: 'Series',
  [BookBadgeCategoryTypeEnum.EXCLUSIVE]: 'Exclusive',
}
