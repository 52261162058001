import { Container } from '@components/Container'
import { BadgeManagementForm } from '@features/badgeSetting/components/BadgeManagementForm'
import { BadgeManagementEditPageProps } from './interface'

export function BadgeManagementEditPage({
  initialValues,
  onSubmit,
}: BadgeManagementEditPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>แก้ไข Badge</Container.Title>

      <BadgeManagementForm
        editMode
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </Container>
  )
}
