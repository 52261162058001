import { useRef } from 'react'
import { useField } from 'formik'

import type {
  FileType,
  UploadFileBookIdFieldAcceptProps,
  UploadFileBookIdFieldProps,
} from './interface'

const withUploadFileUserIdField = (
  Component: React.FC<UploadFileBookIdFieldProps>
) => {
  function WithUploadFileUserIdField({
    name,
    fieldValueName,
    fieldErrorName,
    setFieldTouched,
    setFieldValue,
    ...props
  }: UploadFileBookIdFieldAcceptProps) {
    const [{ value }, { error }, { setValue }] = useField<FileType | undefined>(
      name
    )
    const inputRef = useRef<HTMLInputElement>(null)
    const fileName = useRef(value?.name)

    async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target
      if (files) {
        try {
          // extract ids from csv file
          const file = files[0]
          const reader = new FileReader()

          reader.onload = async e => {
            const text = e.target?.result as string
            const lines = text.split('\n')
            const ids = lines
              .filter(
                id =>
                  Boolean(id.replaceAll('\r', '')) && !Number.isNaN(Number(id))
              )
              .map(id => Number(id.replaceAll('\r', '')))

            setValue({
              name: file.name,
              blob: file,
            })
            fileName.current = file.name

            if (!lines.length) {
              setFieldValue(
                fieldErrorName,
                'รูปแบบไฟล์ไม่ถูกต้อง ไม่สามารถใช้ได้'
              )
              return
            }

            if (!ids.length) {
              setFieldValue(
                fieldErrorName,
                'ไม่พบข้อมูล Book ID ในไฟล์ที่เลือก'
              )
              return
            }

            setFieldValue(fieldErrorName, undefined)
            setFieldValue(fieldValueName, ids)
          }

          reader.readAsText(file)
        } finally {
          // eslint-disable-next-line no-param-reassign
          event.target.value = '' // DECS: clear input value for case that select same file
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    function onClearSelectedFile() {
      fileName.current = undefined
      setValue(undefined)
      setFieldValue(fieldValueName, [])
      setFieldValue(fieldErrorName, undefined)
      setFieldTouched(fieldValueName, true)
    }

    const newProps = {
      ...props,
      inputRef,
      value,
      fileName: fileName.current,
      isCheckingFile: false,
      isError: !!error,
      onChange: handleChange,
      showUploadDialog,
      onClearSelectedFile,
    }
    return <Component {...newProps} />
  }
  return WithUploadFileUserIdField
}

export default withUploadFileUserIdField
