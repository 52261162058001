import { Expose, Transform } from 'class-transformer'

import type { ImageType } from '@interfaces/ImageType'

export class BadgeManagementFormType {
  @Expose() id?: number

  @Expose() name: string

  @Expose({ name: 'imageUrl' })
  @Transform(
    ({ value }) => ({
      url: value,
      blob: undefined,
    }),
    {
      toClassOnly: true,
    }
  )
  upload: ImageType
}
