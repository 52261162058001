import { Link } from 'react-router-dom'
import cn from 'classnames'

import { Container } from '@components/Container'
import { Button } from '@components/Button'
import { Box } from '@components/Box'
import { ArrowLeftIcon, BinIcon, PenIcon } from '@components/Icons'
import { Pagination } from '@components/Pagination'
import { SelectButtonLine } from '@components/SelectButtonLine'
import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import type { BookBadgeCategory } from '@models/badge/BadgeRequestResponse'
import type { BadgeManagementPageProps } from './interface'

export function BadgeManagementPage({
  data,
  page,
  perpage,
  total,
  tab,
  tabOption,
  handleTabChange,
  handleEditBadge,
  handleDeleteBadge,
  handleClickBack,
  pageChange,
}: BadgeManagementPageProps) {
  const permission = usePermissionAction('badgeSetting')
  return (
    <Container>
      <section className='flex items-center justify-between mb-[30px]'>
        <div className='flex items-center gap-x-[20px] mb-[28px]'>
          <button
            className='text-white bg-secondary px-[18px] py-[8px] rounded-[20px]'
            type='button'
            onClick={handleClickBack}
          >
            <ArrowLeftIcon width='24' height='24' />
          </button>

          <div className='text-[24px] text-secondary font-medium font-mitr flex-1'>
            Badge Library
          </div>
        </div>
        <div className='flex items-center gap-x-[10px]'>
          <Link to={`/badge/badge-management/add/${tab.toLowerCase()}`}>
            <Button
              type='button'
              className={cn('text-[14px] text-white bg-secondary', {
                hidden: !permission.create,
              })}
            >
              + เพิ่ม Badge
            </Button>
          </Link>
        </div>
      </section>

      <SelectButtonLine
        options={tabOption}
        value={tab}
        className='mt-[20px] mb-[30px]'
        onChange={handleTabChange}
      />

      <section>
        {data.length ? (
          <>
            <div className='grid grid-cols-6 gap-[15px] mb-[20px]'>
              {data?.map((item: BookBadgeCategory) => (
                <div
                  key={item.id}
                  className='flex flex-col justify-start gap-y-[10px]'
                >
                  <Box className='p-0 w-fit'>
                    <img
                      className='w-[159px] aspect-square rounded-[8px]'
                      alt=''
                      src={item.bookBadgeImageUrl}
                    />
                  </Box>
                  <div className='border-b-[1px] font-bold text-secondary pb-[10px] border-[#eee]'>
                    {item?.name}
                  </div>
                  <div className='flex items-center gap-x-[10px]'>
                    <button
                      type='button'
                      onClick={() => handleEditBadge(item?.id)}
                      className={cn(
                        'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                        {
                          hidden: !permission.edit,
                        }
                      )}
                    >
                      <PenIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </button>
                    <button
                      type='button'
                      className={cn(
                        'flex items-center justify-center text-black-970 border border-gray rounded-lg w-[30px] h-[30px] p-0',
                        {
                          hidden: !permission.delete,
                        }
                      )}
                      onClick={() => handleDeleteBadge(item?.id)}
                    >
                      <BinIcon
                        width='20'
                        height='20'
                        className='text-secondary-100'
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <Pagination
              page={page}
              total={total}
              perpage={perpage}
              pageChange={pageChange}
            />
          </>
        ) : (
          <Link
            to='/badge/badge-management/add'
            className={cn({ hidden: !permission.create })}
          >
            <button
              className='flex justify-center items-center border-dashed border-[1px] border-secondary-100 w-[159px] h-[159px] rounded-[8px] bg-white'
              type='button'
            >
              <span className='font-mitr font-medium text-[14px] text-secondary-100 underline'>
                + เพิ่ม Badge
              </span>
            </button>
          </Link>
        )}
      </section>
    </Container>
  )
}
