import { gql } from 'graphql-request'

export const GET_BOOK_BADGES = gql`
  query GetBookBadges(
    $limitPerPage: Int
    $page: Int
    $search: String
    $badgeCategoryType: BookBadgeCategoryTypeEnum
  ) {
    getBookBadges(
      limitPerPage: $limitPerPage
      page: $page
      searchKeyword: $search
      badgeCategoryType: $badgeCategoryType
    ) {
      data {
        bookId
        bookTitle
        bookBadges {
          bookBadgeId
          badgeCategoryType
          startDate
          endDate
        }
      }
      total
    }
  }
`
