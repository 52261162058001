import { ConfirmModal } from '@components/modals/ConfirmModal'
import { ConfirmModalProps } from '@components/modals/ConfirmModal/interface'
import { LoadingModal } from '@components/modals/LoadingModal/LoadingModal'
import { BanModal } from '@components/StoryCard/components/BanModal'
import { BanModalProps } from '@components/StoryCard/components/BanModal/interface'
import {
  WriterTermsOfServiceModal,
  WriterTermsOfServiceModalProps,
} from '@features/user/pages/WriterSubmitDetailPage/components/WriterTermsOfServiceModal'
import { WriterRejectModal } from '@features/user/pages/WriterSubmitDetailPage/components/WriterRejectModal'
import { WithWriterRejectModalProps } from '@features/user/pages/WriterSubmitDetailPage/components/WriterRejectModal/interface'
import { AgeConfirmRejectModal } from '@features/user/pages/AgeConfirmationDetailPage/components/AgeConfirmRejectModal'
import { WithAgeConfirmRejectModalProps } from '@features/user/pages/AgeConfirmationDetailPage/components/AgeConfirmRejectModal/interface'
import { ViewAllSponsorsModal } from '@features/preview/pages/PreviewStoryPage/components/PayRank/components/ViewAllSponsorsModal'
import { WithViewAllSponsorsModalProps } from '@features/preview/pages/PreviewStoryPage/components/PayRank/components/ViewAllSponsorsModal/interface'
import { PennameModal } from '@features/user/pages/UserManagementPage/components/UserManagementTable/components/PennameModal'
import {
  EditCoinModal,
  EditCoinModalProps,
} from '@features/coin/pages/CoinPage/components/EditCoinModal'
import {
  AdminRevisionHistory,
  AdminRevisionHistoryProps,
} from '@components/AdminRevisionHistory'
import { ReportDetailActivityModalAcceptProps } from '@features/chapterBook/pages/ReportListDetailPage/components/ReportDetailActivityModal/interface'
import { ReportDetailActivityModal } from '@features/chapterBook/pages/ReportListDetailPage/components/ReportDetailActivityModal'
import { PublishActivityModalAcceptProps } from '@features/chapterBook/pages/ReportListDetailPage/components/PublishActivityModal/interface'
import { PublishActivityModal } from '@features/chapterBook/pages/ReportListDetailPage/components/PublishActivityModal'
import { WithConfirmToCreateModalProps } from '@features/promotionSetting/components/PromotionCodeForm/components/ConfirmToCreateModal/interface'
import { ConfirmToCreateModal } from '@features/promotionSetting/components/PromotionCodeForm/components/ConfirmToCreateModal'
import { LeaveCreateModal } from '@features/promotionSetting/components/PromotionCodeForm/components/LeaveCreateModal'
import { WithLeaveCreateModalProps } from '@features/promotionSetting/components/PromotionCodeForm/components/LeaveCreateModal/interface'
import { PromotionSettingCloseModal } from '@features/promotionSetting/components/PromotionSettingCloseModal'
import { PromotionSettingCloseModalProps } from '@features/promotionSetting/components/PromotionSettingCloseModal/interface'
import { RejectPromotionModal } from '@features/promotionSetting/pages/PromotionSettingPage/components/RejectPromotionModal'
import { RejectPromotionModalAcceptProps } from '@features/promotionSetting/pages/PromotionSettingPage/components/RejectPromotionModal/interface'
import { BanUserModalAcceptProps } from '@features/user/pages/UserManagementPage/components/UserManagementTable/components/BanUserModal/interface'
import { BanUserModal } from '@features/user/pages/UserManagementPage/components/UserManagementTable/components/BanUserModal'
import {
  BadgeCategoryModal,
  BadgeCategoryModalProps,
} from '@features/badgeSetting/components/BadgeCategoryModal'

export const ModalList = {
  // DESC: Setup key and component
  ban: (props: BanModalProps) => <BanModal {...props} />,
  confirm: (props: ConfirmModalProps) => <ConfirmModal {...props} />,
  loading: () => <LoadingModal />,
  writerTermsOfService: (props: WriterTermsOfServiceModalProps) => (
    <WriterTermsOfServiceModal {...props} />
  ),
  writerReject: (props: WithWriterRejectModalProps) => (
    <WriterRejectModal {...props} />
  ),
  ageConfirmReject: (props: WithAgeConfirmRejectModalProps) => (
    <AgeConfirmRejectModal {...props} />
  ),
  viewAllSponsors: (props: WithViewAllSponsorsModalProps) => (
    <ViewAllSponsorsModal {...props} />
  ),
  pennameModal: (props: any) => <PennameModal {...props} />,
  editCoin: (props: EditCoinModalProps) => <EditCoinModal {...props} />,
  adminRevisionHistoryLogs: (props: AdminRevisionHistoryProps) => (
    <AdminRevisionHistory {...props} />
  ),
  reportDetailActivity: (props: ReportDetailActivityModalAcceptProps) => (
    <ReportDetailActivityModal {...props} />
  ),
  publishActivity: (props: PublishActivityModalAcceptProps) => (
    <PublishActivityModal {...props} />
  ),
  confirmCreatePromotionCode: (props: WithConfirmToCreateModalProps) => (
    <ConfirmToCreateModal {...props} />
  ),
  leaveCreatePromotionCode: (props: WithLeaveCreateModalProps) => (
    <LeaveCreateModal {...props} />
  ),
  closePromotion: (props: PromotionSettingCloseModalProps) => (
    <PromotionSettingCloseModal {...props} />
  ),
  rejectPromotion: (props: RejectPromotionModalAcceptProps) => (
    <RejectPromotionModal {...props} />
  ),
  banUser: (props: BanUserModalAcceptProps) => <BanUserModal {...props} />,
  badgeCategory: (props: BadgeCategoryModalProps) => (
    <BadgeCategoryModal {...props} />
  ),
}
