import React from 'react'
import type {
  BadgeSearchFormProps,
  WithBadgeSearchFormProps,
} from './interface'

export function withBadgeSearchForm(Component: React.FC<BadgeSearchFormProps>) {
  function HOC(props: WithBadgeSearchFormProps) {
    return <Component {...props} />
  }

  return HOC
}
