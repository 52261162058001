import { SelectMenu } from '@components/layouts/components/SelectMenu/SelectMenu'
import { useAdminMenuBadge } from '@hooks/menu/useAdminMenuBadge'
import { PermissionGuard } from '@libs/permission'

export function SideMenu() {
  const { menuNotice } = useAdminMenuBadge()

  const menus = [
    {
      label: 'Dashboard',
      path: '/dashboard/sales-report',
      isAlarm: false,
      name: 'dashboard',
      basePath: '/dashboard',
      children: [],
    },
    {
      label: 'Sale',
      path: '/sales/sales-report',
      isAlarm: false,
      name: 'sale',
      basePath: '/sales',
      children: [],
    },
    {
      label: 'Home page',
      path: '',
      isAlarm: false,
      name: 'homePageManagement',
      basePath: '/home-page',
      children: [
        {
          label: 'Main Banner',
          path: '/home-page/main-banner',
          basePath: '/home-page/main-banner',
          name: 'mainBanner',
          isAlarm: false,
        },
        {
          label: 'Highlight Banner 1',
          path: '/home-page/highlight-banner/top',
          basePath: '/home-page/highlight-banner/top',
          name: 'highlightBanner1',
          isAlarm: false,
        },
        {
          label: 'Highlight Banner 2',
          path: '/home-page/highlight-banner/bottom',
          basePath: '/home-page/highlight-banner/bottom',
          name: 'highlightBanner2',
          isAlarm: false,
        },
        {
          label: 'Top View',
          path: '/home-page/top-view',
          basePath: '/home-page/top-view',
          isAlarm: false,
        },
        {
          label: 'Top Purchase',
          path: '/home-page/top-purchase',
          basePath: '/home-page/top-purchase',
          name: 'topPurchase',
          isAlarm: false,
        },
        {
          label: 'Pop-up Banner',
          path: '/home-page/pop-up/pop-up-management',
          basePath: '/home-page/pop-up',
          name: 'popUp',
          isAlarm: false,
        },
      ],
    },
    {
      label: 'Menu Management',
      path: '/menu-management',
      isAlarm: false,
      name: 'menuManagement',
      basePath: '/menu-management',
      children: [],
    },
    {
      label: 'Chapter Book',
      path: '',
      isAlarm: !!menuNotice?.reportManagement,
      name: 'chapterBook',
      basePath: '/chapter-book',
      children: [
        {
          label: 'Book Story Setting',
          path: '/chapter-book/book-story-setting',
          basePath: '/chapter-book/book-story-setting',
          name: 'bookStorySetting',
          isAlarm: false,
        },
        {
          label: 'Category Management',
          path: '/chapter-book/manage-category',
          basePath: '/chapter-book/manage-category',
          name: 'categoryManagement',
          isAlarm: false,
        },
        {
          label: 'Cover Management',
          path: '/chapter-book/cover',
          basePath: '/chapter-book/cover',
          name: 'coverManagement',
          isAlarm: false,
        },
        {
          label: 'Sticker Management',
          path: '/chapter-book/sticker-management',
          basePath: '/chapter-book/sticker-management',
          name: 'stickerManagement',
          isAlarm: false,
        },
        {
          label: 'Report List',
          path: '/chapter-book/report-list',
          basePath: '/chapter-book/report-list',
          isAlarm: !!menuNotice?.reportManagement,
        },
      ],
    },
    {
      label: 'Promotion Setting',
      path: '/promotion-setting',
      isAlarm: !!menuNotice?.promotionSetting,
      name: 'promotionSetting',
      basePath: '/promotion-setting',
      children: [],
    },
    {
      label: 'Badge',
      path: '/badge',
      isAlarm: false,
      name: 'badgeSetting',
      basePath: '/badge',
      children: [],
    },
    {
      label: 'News & Promotion',
      path: '/news-promotion',
      isAlarm: false,
      name: 'newsPromotion',
      basePath: '/news-promotion',
      children: [],
    },
    {
      label: 'User',
      path: '',
      isAlarm: !!menuNotice?.writerSubmit || !!menuNotice?.ageConfirmation,
      name: 'user',
      basePath: '/user',
      children: [
        {
          label: 'User Management',
          path: '/user/user-management',
          basePath: '/user/user-management',
          name: 'userManagement',
          isAlarm: false,
        },
        {
          label: 'Sales by User',
          path: '/user/sales-by-user',
          basePath: '/user/sales-by-user',
          name: 'salesByUser',
          isAlarm: false,
        },
        {
          label: 'Writer Submit',
          path: '/user/writer-submit',
          basePath: '/user/writer-submit',
          name: 'writerSubmit',
          isAlarm: !!menuNotice?.writerSubmit,
        },
        {
          label: 'Age Confirmation',
          path: '/user/age-confirmation',
          basePath: '/user/age-confirmation',
          name: 'ageConfirmation',
          isAlarm: !!menuNotice?.ageConfirmation,
        },
      ],
    },
    {
      label: 'User Mission',
      path: '/user-mission',
      isAlarm: false,
      name: 'userMission',
      basePath: '/user-mission',
      children: [],
    },
    {
      label: 'Coin',
      path: '/coin/summary',
      isAlarm: !!menuNotice?.coin,
      name: 'coin',
      basePath: '/coin',
      children: [],
    },
    {
      label: 'Search Management',
      path: '/search-management/history',
      isAlarm: false,
      name: 'searchManagement',
      basePath: '/search-management',
      children: [],
    },
    {
      label: 'Admin',
      path: '/admin/admin-user-management',
      isAlarm: false,
      name: 'admin',
      basePath: '/admin',
      children: [],
    },
  ]

  return (
    <ul className='w-full px-[30px] shadow overflow-auto scrollbar-none'>
      {menus.map((menu, index) => (
        <PermissionGuard key={index} feature={menu.name} action='view'>
          <div>
            <SelectMenu
              label={menu}
              name={menu.name}
              option={menu?.children}
              isToggle={!!menu?.children.length}
              isAlarm={menu?.isAlarm}
              basePath={menu.basePath}
            />
            <hr />
          </div>
        </PermissionGuard>
      ))}
    </ul>
  )
}
