import { BookBadgeCategoryTypeEnum } from '@features/badgeSetting/pages/BadgeListPage/interface'
import { Expose, Type } from 'class-transformer'

export class BookBadge {
  @Expose()
  bookBadgeId: number

  @Expose()
  badgeCategoryType: BookBadgeCategoryTypeEnum

  @Expose()
  startDate: string

  @Expose()
  endDate: string
}

export class BookBadgeCategory {
  @Expose()
  id: number

  @Expose()
  name: string

  @Expose()
  bookBadgeType: BookBadgeCategoryTypeEnum

  @Expose()
  createdAt: string

  @Expose()
  updatedAt: string

  @Expose()
  deletedAt: string

  @Expose()
  bookBadgeImageUrl: string
}

export class BadgeRequestType {
  @Expose()
  bookId: number

  @Expose()
  bookTitle: string

  @Expose()
  @Type(() => BookBadge)
  bookBadges: BookBadge[]
}

export class BadgeRequestResponse {
  @Type(() => BadgeRequestType)
  data: BadgeRequestType[]

  total: number

  page: number
}

export class BadgeCategoryRequestResponse {
  @Type(() => BookBadgeCategory)
  data: BookBadgeCategory[]

  total: number

  page: number
}
