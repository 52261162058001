import { Outlet } from 'react-router-dom'

import { BadgeSettingPage } from '@features/badgeSetting/pages/BadgeListPage'
import { BadgeManagementEditPage } from '@features/badgeSetting/pages/BadgeEditPage'
import { BadgeManagementAddPage } from '@features/badgeSetting/pages/BadgeAddPage'
import { BadgeManagementPage } from '@features/badgeSetting/pages/BadgeManagementPage'
import { BookBadgeAddPage } from '@features/badgeSetting/pages/BookBadgeAddPage'
import { EditBadgeProvider } from '@hooks/badge/useEditBadgeCategory'

export const badgeSettingRoutes = [
  {
    path: 'badge',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <BadgeSettingPage />,
      },
      {
        path: ':type',
        element: <BadgeSettingPage />,
      },
      {
        path: 'create',
        element: <BookBadgeAddPage />,
      },
      {
        path: 'badge-management',
        element: (
          <EditBadgeProvider>
            <Outlet />
          </EditBadgeProvider>
        ),
        children: [
          {
            path: '',
            element: <BadgeManagementPage />,
          },
          {
            path: ':type',
            element: <BadgeManagementPage />,
          },
          {
            path: 'add',
            element: <BadgeManagementAddPage />,
          },
          {
            path: 'add/:type',
            element: <BadgeManagementAddPage />,
          },
          {
            path: 'edit',
            element: <BadgeManagementEditPage />,
          },
        ],
      },
    ],
  },
]
