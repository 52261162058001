import { GraphQLClient } from 'graphql-request'
import { CategoryClient } from './collections/Category/CategoryClient'
import { FileClient } from './collections/File/FileClient'
import { MainBannerClient } from './collections/MainBanner/MainBannerClient'
import { HighlightBannerClient } from './collections/HighlightBanner/HighlightBanner'
import { AdminClient } from './collections/Admin/AdminClient'
import { UserClient } from './collections/User/UserClient'
import { UserManagementClient } from './collections/UserManagement/UserManagementClient'
import { StickerClient } from './collections/Sticker/StickerClient'
import { BookStorySettingClient } from './collections/BookStorySetting/BookStorySettingClient'
import { AgeConfirmClient } from './collections/AgeConfirm/AgeConfirmClient'
import { UserWriterClient } from './collections/UserWriter/UserWriterClient'
import { SearchManagementClient } from './collections/SearchManagement/SearchManagementClient'
import { StoryClient } from './collections/Story/StoryClient'
import { ChapterClient } from './collections/Chapter/ChapterClient'
import { UserMissionClient } from './collections/UserMission/UserMissionClient'
import { MainMenuClient } from './collections/MainMenus/MainMenuClient'
import { DashboardClient } from './collections/Dashboard/DashboardClient'
import { CoinClient } from './collections/Coin/CoinClient'
import { SaleClient } from './collections/Sale/SaleClient'
import { CharacterClient } from './collections/Character/CharacterClient'
import { NewsClient } from './collections/News/NewsClient'
import { ReportClient } from './collections/Report/ReportClient'
import { SaleByUserClient } from './collections/SaleByUser/SaleByUserClient'
import { PromotionClient } from './collections/Promotion/PromotionClient'
import { PromotionCodeClient } from './collections/PromotionCode/PromotionCodeClient'
import { PromotionChallengeClient } from './collections/PromotionChallenge/PromotionChallengeClient'
import { SupporterClient } from './collections/Supporter/SupporterClient'
import { PopupBannerClient } from './collections/PopupBanner/PopupBannerClient'
import { PromotionSaleClient } from './collections/PromotionSale/PromotionSaleClient'
import { EbookClient } from './collections/Ebook/EbookClient'
import { BadgeClient } from './collections/Badge/BadgeClient'

export class Client {
  constructor(private client: GraphQLClient) {}

  public fileClient = new FileClient(this.client)

  public userClient = new UserClient(this.client)

  public highlightBannerClient = new HighlightBannerClient(
    this.client,
    this.fileClient
  )

  public categoryClient = new CategoryClient(this.client)

  public mainBannerClient = new MainBannerClient(this.client, this.fileClient)

  public userManagementClient = new UserManagementClient(this.client)

  public adminClient = new AdminClient(this.client)

  public stickerClient = new StickerClient(this.client, this.fileClient)

  public bookStorySettingClient = new BookStorySettingClient(this.client)

  public ageConfirmClient = new AgeConfirmClient(this.client)

  public userWriterClient = new UserWriterClient(this.client)

  public searchManagementClient = new SearchManagementClient(this.client)

  public storyClient = new StoryClient(this.client)

  public chapterClient = new ChapterClient(this.client)

  public userMissionClient = new UserMissionClient(this.client)

  public mainMenuClient = new MainMenuClient(this.client)

  public dashboardClient = new DashboardClient(this.client)

  public saleClient = new SaleClient(this.client)

  public coinClient = new CoinClient(this.client)

  public characterClient = new CharacterClient(this.client)

  public newsClient = new NewsClient(this.client)

  public reportClient = new ReportClient(this.client)

  public saleByUserClient = new SaleByUserClient(this.client)

  public promotionClient = new PromotionClient(this.client)

  public badgeClient = new BadgeClient(this.client)

  public promotionCodeClient = new PromotionCodeClient(this.client)

  public promotionChallengeClient = new PromotionChallengeClient(this.client)

  public promotionSaleClient = new PromotionSaleClient(this.client)

  public supporterClient = new SupporterClient(this.client)

  public popupBannerClient = new PopupBannerClient(this.client)

  public ebookClient = new EbookClient(this.client)
}
