import { Container } from '@components/Container'
import { BadgeManagementForm } from '@features/badgeSetting/components/BadgeManagementForm'
import { BadgeManagementAddPageProps } from './interface'
import { bookBadgeCateoryName } from '../BadgeListPage/interface'

export function BadgeManagementAddPage({
  initialValues,
  onSubmit,
  tab,
}: BadgeManagementAddPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn>
        สร้างป้าย (Badge: {bookBadgeCateoryName[tab]})
      </Container.Title>

      <BadgeManagementForm initialValues={initialValues} onSubmit={onSubmit} />
    </Container>
  )
}
