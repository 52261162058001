import { withPermissionGuard } from '@libs/permission'
import { BadgeManagementEditPage } from './BadgeEditPage'
import { withBadgeManagementEditPage } from './withBadgeEditPage'

const ConnectedBadgeManagementEditPage = withPermissionGuard(
  withBadgeManagementEditPage(BadgeManagementEditPage),
  'badgeSetting',
  'edit'
)

export { ConnectedBadgeManagementEditPage as BadgeManagementEditPage }
