import { useEffect } from 'react'
import { useRoutes, Outlet, useNavigate } from 'react-router-dom'

import { AuthenticationLayout } from './components/layouts/AuthenticationLayout'
import { AppLayout } from './components/layouts/AppLayout'
import { dashboardRoutes } from './features/dashboard/dashboardRoutes'
import { homeRoutes } from './features/home/homeRoutes'
import { authenticationRoutes } from './features/authentication/authenticationRoutes'
import { coinRoutes } from './features/coin/coinRoutes'
import { chapterBookRoutes } from './features/chapterBook/chapterBookRoutes'
import { userRoutes } from './features/user/userRoutes'
import { adminRoutes } from './features/admin/adminRoutes'
import { storyRoutes } from './features/story/storyRoutes'
import { searchManagementRoutes } from './features/searchManagement/searchManagementRoutes'
import { previewRoutes } from './features/preview/previewRoutes'
import { promotionSettingRoutes } from './features/promotionSetting/promotionSettingRoutes'
import { salesRoutes } from './features/sales/salesRoutes'
import { userMissionRoutes } from './features/user-mission/userMissionRoutes'
import { menuManagementRoutes } from './features/menuManagement/menuManagementRoutes'
import { newsAndPromotionRoutes } from './features/newsAndPromotion/newsAndPromotionRoutes'
import { storyReaderRoutes } from './features/storyReader/storyReaderRoutes'
import { notificationRoutes } from './features/notification/notificationRoutes'
import { ebookRoutes } from './features/ebook/ebookRoutes'
import { badgeSettingRoutes } from './features/badgeSetting/badgeSettingRoutes'

function RedirectPath() {
  const navigate = useNavigate()

  useEffect(() => {
    // TODO: จะไปไหนก็ไปๆ
    navigate({ pathname: '' })
  }, [navigate])

  return null
}

function App() {
  const routes = useRoutes([
    {
      path: '',
      element: (
        <AppLayout>
          <Outlet />
        </AppLayout>
      ),
      children: [
        ...dashboardRoutes,
        ...homeRoutes,
        ...coinRoutes,
        ...chapterBookRoutes,
        ...userRoutes,
        ...adminRoutes,
        ...searchManagementRoutes,
        ...storyRoutes,
        ...promotionSettingRoutes,
        ...badgeSettingRoutes,
        ...salesRoutes,
        ...userMissionRoutes,
        ...menuManagementRoutes,
        ...newsAndPromotionRoutes,
        ...storyReaderRoutes,
        ...previewRoutes,
        ...notificationRoutes,
        ...ebookRoutes,
      ],
    },
    {
      path: '',
      element: (
        <AuthenticationLayout>
          <Outlet />
        </AuthenticationLayout>
      ),
      children: [...authenticationRoutes],
    },
    {
      path: '*',
      element: <RedirectPath />,
    },
  ])

  return routes
}

export default App
