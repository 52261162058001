import { gql } from 'graphql-request'

export const UPLOAD_BOOK_BADGE_CATEGORY_IMAGE = gql`
  mutation UploadBookBadgeCategoryImage(
    $badgeCategoryId: Int!
    $file: Upload!
  ) {
    uploadBookBadgeCategoryImage(
      badgeCategoryId: $badgeCategoryId
      file: $file
    ) {
      filePath
      key
    }
  }
`
