import React from 'react'
import type {
  BadgeListTableAcceptProps,
  BadgeListTableProps,
} from './interface'

const withBadgeListTable = (Component: React.FC<BadgeListTableProps>) => {
  function WithBadgeListTable(props: BadgeListTableAcceptProps) {
    const newProps = {
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithBadgeListTable
}

export default withBadgeListTable
