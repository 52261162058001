import { createContext, Reducer, useContext, useReducer } from 'react'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

interface EditBadgeContextProviderValue {
  state: InitialData
  dispatch: React.Dispatch<Action>
}

interface InitialData {
  id: number
  name: string
  imageUrl: string
}

const initialState: InitialData = {
  id: 0,
  name: '',
  imageUrl: '',
}

type Action = { type: 'set'; payload: InitialData } | { type: 'reset' }

const reducer: Reducer<InitialData, Action> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload }
    case 'reset':
      return { ...initialState }
    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`)
  }
}

const EditBadgeContext = createContext<EditBadgeContextProviderValue>({
  state: initialState,
  dispatch: () => {},
})

export function EditBadgeProvider({ children }: FunctionComponentType) {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <EditBadgeContext.Provider value={{ state, dispatch }}>
      {children}
    </EditBadgeContext.Provider>
  )
}

export function useEditBadgeCategory() {
  return useContext(EditBadgeContext)
}
