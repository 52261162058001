import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { getErrorMessage } from '@libs/utils'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useEditBadgeCategory } from '@hooks/badge/useEditBadgeCategory'
import { BadgeManagementPageProps } from './interface'
import {
  BookBadgeCategoryTypeEnum,
  bookBadgeCateoryName,
} from '../BadgeListPage/interface'

const PER_PAGE = 24
const REMOVE_MESSAGE_SUCCESS = 'ลบ Badge สำเร็จ'
const tabOption = [
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.EXCLUSIVE],
    value: BookBadgeCategoryTypeEnum.EXCLUSIVE,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.MOVIES_SERIES],
    value: BookBadgeCategoryTypeEnum.MOVIES_SERIES,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.BESTSELLER],
    value: BookBadgeCategoryTypeEnum.BESTSELLER,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.AWARD],
    value: BookBadgeCategoryTypeEnum.AWARD,
  },
]

export function withBadgeManagementPage(
  Component: React.FC<BadgeManagementPageProps>
) {
  function WithBadgeManagementPage() {
    const navigate = useNavigate()
    const client = useClient()
    const alert = useAlert()
    const { dispatch } = useEditBadgeCategory()
    const { type } = useParams()
    const [tab, setTab] = useState<BookBadgeCategoryTypeEnum>(
      (type?.toUpperCase() ?? tabOption[0].value) as BookBadgeCategoryTypeEnum
    )
    const confirmModal = useModal({ modal: 'confirm' })
    const { page, total, perpage, handleTotalChange, pageChange } =
      usePagination({ perpage: PER_PAGE })
    const { data = [], refetch } = useQuery(
      ['badge-category-list', 'page', page, 'perpage', PER_PAGE, 'tab', tab],
      () =>
        client?.badgeClient
          .getBookBadgeCategories({
            page,
            limitPerPage: PER_PAGE,
            type: tab,
          })
          .then(res => {
            handleTotalChange(res.total)

            return res.data
          })
    )

    const { mutate: removeBadge } = useMutation(
      (id: number) => client!.badgeClient.removeBookBadgeCategory({ id }),
      {
        onSuccess: () => {
          refetch()
          alert.success(REMOVE_MESSAGE_SUCCESS)
        },
        onError: error => {
          const message = getErrorMessage(error)
          alert.error(message)
        },
      }
    )

    useEffect(() => {
      const formattedType =
        type?.toUpperCase() ?? ('' as BookBadgeCategoryTypeEnum)
      if (
        Object.values(BookBadgeCategoryTypeEnum).includes(
          formattedType as BookBadgeCategoryTypeEnum
        )
      ) {
        setTab(formattedType as BookBadgeCategoryTypeEnum)
      } else {
        setTab(tabOption[0].value)
      }

      /* eslint-disable react-hooks/exhaustive-deps */
    }, [])

    function handleEditBadge(id: number) {
      const target = data.find(item => item.id === id)
      if (!target) {
        dispatch({ type: 'reset' })
        return
      }
      dispatch({
        type: 'set',
        payload: {
          id,
          name: target.name,
          imageUrl: target.bookBadgeImageUrl,
        },
      })
      navigate({ pathname: '/badge/badge-management/edit/' })
    }

    function handleDeleteBadge(id: number) {
      const badgeName = data.find(item => item.id === id)?.name
      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px]'>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบ Badge <br /> {badgeName} <br />
              หรือไม่?
            </p>
          </div>
        ),
        onConfirm: () => {
          removeBadge(id)
          confirmModal.hide()
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    function onActiveTabChange(value: BookBadgeCategoryTypeEnum) {
      pageChange(1)
      setTab(value)
      navigate({ pathname: `/badge/badge-management/${value.toLowerCase()}` })
    }

    function handleGoback() {
      navigate({ pathname: '/badge' })
    }

    const newProps = {
      data,
      page,
      perpage,
      total,
      handleEditBadge,
      handleDeleteBadge,
      pageChange,
      tab,
      tabOption,
      handleClickBack: handleGoback,
      handleTabChange: onActiveTabChange,
    }

    return <Component {...newProps} />
  }

  return WithBadgeManagementPage
}
