import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Button } from '@components/Button'
import { Box } from '@components/Box'
import { InputField } from '@components/forms/InputField'
import { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import { UploadBadgeField } from '../UploadBadgeField'
import { UploadBadgeButtonField } from '../UploadBadgeButtonField'
import type { StickerManagementFormProps } from './interface'

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    upload: yup.object().shape({
      url: yup.string().required(),
    }),
  })
  .test((value, context) => {
    if (
      (typeof value.name === 'string' && !value.name.trim()) ||
      typeof value.name === 'undefined'
    ) {
      return context.createError({
        path: 'name',
        message: 'required',
      })
    }

    return true
  })

export function BadgeManagementForm({
  initialValues,
  onSubmit,
  editMode,
}: StickerManagementFormProps) {
  const navigate = useNavigate()
  async function handleSubmit(form: StickerManagementFormType) {
    await onSubmit({
      ...form,
      name: form.name.trim(),
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ resetForm, dirty, isSubmitting }) => (
        <Form className='w-full'>
          <Box>
            <div className='w-full flex flex-row'>
              <div className='mr-[30px] w-[200px]'>
                <UploadBadgeField />
              </div>
              <div className='w-full flex flex-col justify-between'>
                <InputField
                  name='name'
                  placeholder='ชื่อ'
                  label='ชื่อ'
                  inputClassName='font-light bg-white text-black-480  w-full rounded-[20px] '
                  className='w-full'
                  showErrorMsg={false}
                />
                <UploadBadgeButtonField />
              </div>
            </div>
          </Box>
          <div className='flex gap-x-[20px] font-mitr justify-center items-center text-[14px] font-medium mt-[30px]'>
            <Button
              type='button'
              className='w-[100px] border-[1px] border-secondary-100 text-secondary-100'
              onClick={() => (editMode ? resetForm() : navigate(-1))}
            >
              ยกเลิก
            </Button>
            <Button
              className='text-white bg-primary w-[100px]'
              type='submit'
              disabled={!dirty || isSubmitting}
            >
              บันทึก
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
