import { gql } from 'graphql-request'

export const UPDATE_BOOK_BADGE_CATEGORY = gql`
  mutation UpdateBookBadgeCategory(
    $updateBadgeCategoryInput: UpdateBadgeCategoryInput!
  ) {
    updateBookBadgeCategory(
      updateBadgeCategoryInput: $updateBadgeCategoryInput
    ) {
      id
      name
      bookBadgeType
      createdAt
      updatedAt
      deletedAt
      bookBadgeImageUrl
    }
  }
`
