import { gql } from 'graphql-request'

export const REMOVE_BOOK_BADGE_CATEGORY = gql`
  mutation RemoveBookBadgeCategory(
    $removeBadgeCategoryInput: RemoveBadgeCategoryInput!
  ) {
    removeBookBadgeCategory(
      removeBadgeCategoryInput: $removeBadgeCategoryInput
    ) {
      message
    }
  }
`
