import { BookBadgeCategoryTypeEnum } from '@features/badgeSetting/pages/BadgeListPage/interface'
import { Expose, Transform } from 'class-transformer'

export enum DateOption {
  FOREVER = 'FOREVER',
  RANGE = 'RANGE',
}

export class BookBadgeFormType {
  @Expose() bookIds: number[]

  @Expose()
  @Transform(({ value }) =>
    typeof value === 'string'
      ? value
          .split(',')
          .map(v => parseInt(v.trim(), 10))
          .filter(v => !Number.isNaN(v))
      : value
  )
  bookIdsInput!: number[] | string

  @Expose() bookIdInputError?: string

  @Expose() bookIdFileError?: string

  @Expose() badgeCategoryId: number

  @Expose() dateOption?: DateOption

  @Expose() categoryOption?: BookBadgeCategoryTypeEnum

  @Expose() startDate: Date | string

  @Expose() endDate: Date | string
}
