import { DateTime } from 'luxon'
import { EyeIcon, ThreeDotIcon } from '@components/Icons'
import { useDropdown } from '@hooks/useDropdown'
import { CLIENT_URL } from '@configs/config'

import type { ColumnType } from '@components/Table/interface'
import type {
  BadgeRequestType,
  BookBadge,
} from '@models/badge/BadgeRequestResponse'
import { bookBadgeCateoryName } from '@features/badgeSetting/pages/BadgeListPage/interface'
import type { ActionCellProps } from './interface'

export function ActionCell({ id, bookId, handleRemoveBadge }: ActionCellProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  return (
    <div className='w-full flex justify-end gap-x-2'>
      <a
        href={`${CLIENT_URL}/story/${bookId}`}
        rel='noreferrer'
        target='_blank'
      >
        <button
          className='border border-gray h-[30px] px-[4px] rounded-[8px]'
          type='button'
        >
          <EyeIcon className='text-black-970' width='18' height='18' />
        </button>
      </a>
      <div className='relative'>
        <button
          type='button'
          className='flex items-center justify-center w-[30px] h-[30px]'
          onClick={toggleDropdown}
        >
          <ThreeDotIcon width='18' height='18' className='text-secondary' />
        </button>
        {isShowDropdown && (
          <div
            ref={dropdownRef}
            className='absolute w-[70px] z-10 text-[14px] font-light py-[10px] text-black-480 top-0 right-[35px] bg-white shadow border border-gray rounded-[8px] overflow-y-auto'
          >
            <div
              className='flex items-center hover:bg-[#f2f9ff] hover:text-primary cursor-pointer py-[5px] px-[15px]'
              onClick={() => handleRemoveBadge(bookId, id)}
            >
              ลบ
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export function CellTable({
  data,
  rowHeight = 32,
}: {
  data: (string | React.ReactNode)[][]
  rowHeight?: number
}) {
  return (
    <div className='w-full overflow-hidden'>
      {data.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className='flex border-gray-200'
          style={{ height: rowHeight }}
        >
          {row.map((cell, cellIndex) => (
            <div
              key={cellIndex}
              className='flex-1 flex items-center justify-start text-black-480 text-xs font-light'
            >
              {cell}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export function columns({
  handleRemoveBadge,
}: {
  handleRemoveBadge: ActionCellProps['handleRemoveBadge']
}): ColumnType<BadgeRequestType>[] {
  return [
    {
      column: 'Story ID',
      accessor: 'bookId',
      cell: ({ value }) => (
        <div className='text-black-480 text-xs font-light pt-[6px] leading-[1.8]'>
          {value}
        </div>
      ),
      cellClassName: 'border-l border-r p-2 align-top',
      isSortable: false,
    },
    {
      column: 'Title',
      accessor: 'bookTitle',
      cell: ({ value }) => (
        <p className='text-black-480 text-xs font-light line-clamp-2 break-word leading-[1.8] max-w-[300px] pt-[6px]'>
          {value}
        </p>
      ),
      cellClassName: 'border-r p-2 w-[300px] align-top',
      isSortable: false,
    },
    {
      column: 'Badge',
      accessor: 'bookBadges',
      cell: ({ value }) => (
        <CellTable
          data={value?.map((badge: BookBadge) => [
            bookBadgeCateoryName[badge.badgeCategoryType],
          ])}
        />
      ),
      cellClassName: 'border-r p-2',
      isSortable: false,
    },
    {
      column: 'Start date',
      accessor: 'bookBadges',
      cell: ({ value }) => (
        <CellTable
          data={value?.map((badge: BookBadge) => [
            DateTime.fromISO(badge.startDate?.toString()).toFormat(
              'dd LLL yyyy HH:mm:ss',
              {
                locale: 'th-Th',
              }
            ),
          ])}
        />
      ),
      cellClassName: 'border p-2',
      isSortable: false,
    },
    {
      column: 'End date',
      accessor: 'bookBadges',
      cell: ({ value }) => (
        <CellTable
          data={value?.map((badge: BookBadge) => [
            badge.endDate
              ? DateTime.fromISO(badge.endDate?.toString()).toFormat(
                  'dd LLL yyyy HH:mm:ss',
                  {
                    locale: 'th-Th',
                  }
                )
              : '-',
          ])}
        />
      ),
      cellClassName: 'border p-2',
      isSortable: false,
    },
    {
      column: 'Action',
      actionCell: ({ obj }) => (
        <CellTable
          data={obj?.bookBadges?.map((badge: BookBadge, index: number) => [
            <ActionCell
              key={`action-${badge.bookBadgeId}-${index}`}
              bookId={obj.bookId}
              id={badge.bookBadgeId}
              handleRemoveBadge={() =>
                handleRemoveBadge(obj.bookId, badge.bookBadgeId)
              }
            />,
          ])}
        />
      ),
      cellClassName: 'border-r p-2 min-w-[100px]',
      isAction: true,
    },
  ]
}
