import { useMutation, useQuery } from 'react-query'
import { useState } from 'react'

import { usePermissionAction } from '@hooks/permission/usePermissionAction'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { getErrorMessage } from '@libs/utils'
import {
  UploadFileErrorType,
  UploadFileFormType,
  UploadFilePageProps,
} from './interface'

export function withUploadFilePage(Component: React.FC<UploadFilePageProps>) {
  function WithUploadFilePage() {
    const client = useClient()
    const permission = usePermissionAction('coin')
    const alert = useAlert()
    const [errorMsg, setErrorMsg] = useState<UploadFileErrorType>({
      report: '',
      exchange: '',
    })

    const { data: updatedAt, refetch } = useQuery('updatedAt', () =>
      client?.coinClient.latestUpdateInAppPurchases()
    )

    function getError(value: string, type: 'appleReport' | 'financialReport') {
      const message: string[] = []
      if (value.includes('Invalid file content')) {
        message.push(
          type === 'financialReport' &&
            value.includes('has wrong column name in file content')
            ? 'ข้อมูลไฟล์ไม่ถูกต้อง โปรดตรวจสอบว่าทาง Apple ได้เปลี่ยนสถานะเป็น "Paid" แล้ว'
            : 'ข้อมูลไฟล์ไม่ถูกต้อง โปรดตรวจสอบอีกครั้ง'
        )
      }

      if (value.includes('Invalid file name')) {
        message.push(
          type === 'appleReport'
            ? 'ชื่อไฟล์ไม่ถูกต้อง (AppleReport_yyyymm.csv)'
            : 'ชื่อไฟล์ไม่ถูกต้อง (financial_report_yyyymm.csv)'
        )
      }

      if (value.includes('Invalid file unique')) {
        message.push('อัปโหลดไฟล์ซ้ำ')
      }

      return message.join(', ')
    }

    const { mutateAsync } = useMutation(
      (value: UploadFileFormType) =>
        client!.coinClient.createAppleFinancialReport({
          appleReportFile: value.report!,
          financialReportFile: value.exchange!,
        }),
      {
        onSuccess: async () => {
          await refetch()
          alert.success('อัปโหลดไฟล์สำเร็จ')
        },
        onError: error => {
          const message = getErrorMessage(error)
          const err: {
            appleReportFile: string[]
            financialReportFile: string[]
          } = JSON.parse(message)
          const appleReportMessage = getError(
            err.appleReportFile.join(', '),
            'appleReport'
          )
          const financialReportMessage = getError(
            err.financialReportFile.join(', '),
            'financialReport'
          )

          setErrorMsg({
            report: appleReportMessage,
            exchange: financialReportMessage,
          })
        },
      }
    )

    async function onSubmit(value: UploadFileFormType) {
      await mutateAsync(value)
    }

    const componentProps = {
      canEdit: permission.edit,
      updatedAt,
      errorMsg,
      onSubmit,
      setErrorMsg,
    }

    return <Component {...componentProps} />
  }

  return WithUploadFilePage
}
