import type { BookBadgeCategory } from '@models/badge/BadgeRequestResponse'
import { Form, Formik } from 'formik'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import { Pagination } from '@components/Pagination'
import { Box } from '@components/Box'
import { Container } from '@components/Container'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { BadgeCategoryModalFormType } from '@models/badge/BadgeCategoryModal'
import { Button } from '@components/Button'
import { useClient } from '@hooks/useClient'
import { usePagination } from '@hooks/usePagination'
import { Checkbox } from '@components/Checkbox'
import {
  BookBadgeCategoryTypeEnum,
  bookBadgeCateoryName,
} from '../pages/BadgeListPage/interface'

export interface BadgeCategoryModalProps {
  type: BookBadgeCategoryTypeEnum
  initialValues: BadgeCategoryModalFormType
  onSubmit: (value: BadgeCategoryModalFormType) => Promise<void>
}

const PER_PAGE = 12
const validationSchema = Yup.object().shape({
  badgeCategoryId: Yup.number().required(),
})

export function BadgeCategoryModal({
  type,
  initialValues,
  onSubmit,
}: BadgeCategoryModalProps) {
  const badgeCategoryModal = useModal({ modal: 'badgeCategory' })
  const { page, total, perpage, handleTotalChange, pageChange } = usePagination(
    { perpage: PER_PAGE }
  )
  const client = useClient()
  const { data = [] } = useQuery(
    ['badge-category-list', 'page', page, 'perpage', PER_PAGE, 'tab', type],
    () =>
      client?.badgeClient
        .getBookBadgeCategories({
          page,
          limitPerPage: PER_PAGE,
          type,
        })
        .then(res => {
          handleTotalChange(res.total)

          return res.data
        })
  )

  return (
    <Container className='pb-[30px]'>
      <Container.Title className='text-center' showBackBtn={false}>
        เลือกป้าย : {bookBadgeCateoryName[type]}
      </Container.Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={v => {
          onSubmit(v)
          badgeCategoryModal.hide()
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className='w-full'>
            <div>
              <div className='grid grid-cols-6 gap-[15px] mb-[20px]'>
                {data?.map((item: BookBadgeCategory) => (
                  <div
                    key={item.id}
                    className='flex flex-col justify-start gap-y-[10px]'
                  >
                    <Box className='p-0 w-fit'>
                      <img
                        className='w-[159px] aspect-square rounded-[8px]'
                        alt=''
                        src={item.bookBadgeImageUrl}
                      />
                    </Box>
                    <div className='border-b-[1px] font-bold text-secondary pb-[10px] border-[#eee]'>
                      {item?.name}
                    </div>
                    <div className='flex items-center gap-x-[10px]'>
                      <Checkbox
                        label='เลือก'
                        checked={values.badgeCategoryId === item.id}
                        onChange={() => {
                          setFieldValue('badgeCategoryId', item.id)
                          setFieldValue('badgeCategoryName', item.name)
                          setFieldValue(
                            'badgeCategoryImage',
                            item.bookBadgeImageUrl
                          )
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <Pagination
                page={page}
                total={total}
                perpage={perpage}
                pageChange={pageChange}
              />
            </div>
            <div className='border-t border-[#eee] my-[30px]' />
            <div className='flex gap-x-[20px] font-mitr justify-center items-center text-[14px] font-medium'>
              <Button
                type='button'
                className='w-[100px] border border-secondary-100 text-secondary-100'
                onClick={() => badgeCategoryModal.hide()}
              >
                ยกเลิก
              </Button>
              <Button className='text-white bg-primary w-[100px]' type='submit'>
                ตกลง
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
