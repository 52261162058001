import { withPermissionGuard } from '@libs/permission'
import { BadgeManagementAddPage } from './BadgeAddPage'
import { withBadgeManagementAddPage } from './withBadgeAddPage'

const ConnectedBadgeBadgeManagementAddPage = withPermissionGuard(
  withBadgeManagementAddPage(BadgeManagementAddPage),
  'badgeSetting',
  'create'
)

export { ConnectedBadgeBadgeManagementAddPage as BadgeManagementAddPage }
