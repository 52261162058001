import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { plainToInstance } from 'class-transformer'

import { useClient } from '@hooks/useClient'
import { BadgeManagementFormType } from '@models/badge/BadgeManagementFormType'
import { useEditBadgeCategory } from '@hooks/badge/useEditBadgeCategory'
import type { BadgeManagementEditPageProps } from './interface'

export function withBadgeManagementEditPage(
  Component: React.FC<BadgeManagementEditPageProps>
) {
  function WithBadgeManagementEditPage() {
    const navigate = useNavigate()
    const client = useClient()
    const { state: data } = useEditBadgeCategory()

    const { mutate: updateBadge } = useMutation(
      (form: BadgeManagementFormType) =>
        client!.badgeClient.updateBadgeCategory(form),
      {
        onSuccess: () => {
          navigate(-1)
        },
      }
    )

    async function onSubmit(form: BadgeManagementFormType) {
      updateBadge(form)
    }

    if (!data.id) {
      navigate({
        pathname: '/badge/badge-management',
      })
      return null
    }

    const newProps = {
      initialValues: plainToInstance(BadgeManagementFormType, data),
      onSubmit,
    }
    return <Component {...newProps} />
  }

  return WithBadgeManagementEditPage
}
