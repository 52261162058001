import { Container } from '@components/Container'
import { BookBadgeForm } from '@features/badgeSetting/components/BookBadgeForm'

import type { BookBadgeAddPageProps } from './interface'

export function BookBadgeAddPage({
  initialValues,
  handleSubmit,
  handleClickBack,
  isSubmitting,
}: BookBadgeAddPageProps) {
  return (
    <Container>
      <Container.Title showBackBtn onClickBackBtn={handleClickBack}>
        สร้างป้าย (Badge)
      </Container.Title>

      <BookBadgeForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </Container>
  )
}
