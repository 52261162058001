import { useState } from 'react'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { BadgeCategoryModalFormType } from '@models/badge/BadgeCategoryModal'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { Box } from '@components/Box'
import { Button } from '@components/Button'
import { InputField } from '@components/forms/InputField'
import { UploadFileBookIdField } from '@components/UploadFileBookIdField'
import { PromotionRadioInputField } from '@components/forms/PromotionRadioInputField'
import { DateTimeInputField } from '@components/forms/DateTimeInputField'
import { SelectField } from '@components/forms/SelectField'
import { DateOption } from '@models/badge/BookBadgeFormType'
import {
  BookBadgeCategoryTypeEnum,
  bookBadgeCateoryName,
} from '@features/badgeSetting/pages/BadgeListPage/interface'
import { diffDate } from '@libs/utils'

import type { BookBadgeFormProps } from './interface'

const categoryOption = [
  {
    label: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.EXCLUSIVE],
    value: BookBadgeCategoryTypeEnum.EXCLUSIVE,
  },
  {
    label: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.MOVIES_SERIES],
    value: BookBadgeCategoryTypeEnum.MOVIES_SERIES,
  },
  {
    label: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.BESTSELLER],
    value: BookBadgeCategoryTypeEnum.BESTSELLER,
  },
  {
    label: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.AWARD],
    value: BookBadgeCategoryTypeEnum.AWARD,
  },
]

const schema = yup
  .object()
  .shape({
    bookIds: yup.array().of(yup.string()),
    bookIdsInput: yup.string(),
    bookIdInputError: yup.string(),
    bookIdFileError: yup.string(),
    badgeCategoryId: yup.number().min(1).required(),
    dateOption: yup.string().required(),
    startDate: yup.string().when('dateOption', {
      is: (value: DateOption) => value === DateOption.RANGE,
      then: yup.string().required(),
    }),
    endDate: yup.string().when('dateOption', {
      is: (value: DateOption) => value === DateOption.RANGE,
      then: yup.string().required(),
    }),
  })
  .test(value => {
    const hasBookIds = Array.isArray(value.bookIds) && value.bookIds.length > 0
    const hasBookIdsInput =
      typeof value.bookIdsInput === 'string' &&
      value.bookIdsInput.trim() !== '' &&
      !!value.bookIdsInput
        .split(',')
        .map(i => (i?.trim() && +i > 0 ? +i.trim() : 0))
        .filter(i => !!i).length
    return hasBookIds || hasBookIdsInput
  })
  .test(value => {
    if (
      value.dateOption === DateOption.RANGE &&
      (!value.startDate || !value.endDate)
    ) {
      return false
    }

    return true
  })

export function BookBadgeForm({
  initialValues,
  handleSubmit,
  isSubmitting,
}: BookBadgeFormProps) {
  const currentDate = new Date()
  const navigate = useNavigate()
  const badgeCategoryModal = useModal({ modal: 'badgeCategory' })
  const [previewName, setPreviewName] = useState('')
  const [previewImage, setPreviewImage] = useState('')

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ values, dirty, setFieldValue, setFieldTouched }) => {
        const responseStartDiffCurrentDate = diffDate(
          new Date(values.startDate),
          currentDate
        )
        const responseStartDiffEndDate = diffDate(
          new Date(values.startDate),
          new Date(values.endDate)
        )
        const responseEndDiffStartDate = diffDate(
          new Date(values.endDate),
          new Date(values.startDate)
        )
        return (
          <Form className='w-full'>
            <Box>
              <div className='w-full flex flex-row'>
                <div className='w-full flex flex-col gap-y-4 justify-between'>
                  <div className='flex gap-x-4 items-center'>
                    <span className='flex-shrink-0 text-[18px] font-bold text-secondary'>
                      ID เรื่อง
                    </span>
                    <InputField
                      name='bookIdsInput'
                      placeholder='กรอก Book ID คั่นด้วย , (comma)'
                      label=''
                      inputClassName='font-light bg-white text-black-480  w-full rounded-[20px] '
                      className='w-full'
                      disabled={
                        Boolean(values.bookIds.length) ||
                        Boolean(values.bookIdFileError)
                      }
                    />
                  </div>
                  <>
                    <UploadFileBookIdField
                      name='bookIds'
                      fieldValueName='bookIds'
                      fieldErrorName='bookIdFileError'
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      disabled={
                        Boolean(values.bookIdsInput) ||
                        Boolean(values.bookIdFileError)
                      }
                    />
                    {values.bookIdFileError && (
                      <div className='!mt-4 text-red-74 text-xs font-light'>
                        *{values.bookIdFileError}
                      </div>
                    )}
                  </>
                </div>
              </div>
              <div className='flex flex-col gap-y-4 mt-6'>
                <div className='text-[18px] font-bold text-secondary'>
                  เลือกเงื่อนไขการแสดง
                </div>
                <PromotionRadioInputField
                  name='dateOption'
                  label='ตลอดไป'
                  value={DateOption.FOREVER}
                  checked={values.dateOption === DateOption.FOREVER}
                  onInput={() => {
                    setFieldValue('startDate', undefined)
                    setFieldValue('endDate', undefined)
                  }}
                />
                <div className='flex gap-x-4 items-center'>
                  <PromotionRadioInputField
                    name='dateOption'
                    label='ช่วงเวลา'
                    value={DateOption.RANGE}
                    checked={values.dateOption === DateOption.RANGE}
                  />
                  <DateTimeInputField
                    className='w-[178px]'
                    name='startDate'
                    showTimeSelect
                    disabled={values.dateOption !== DateOption.RANGE}
                    minDate={currentDate}
                    maxDate={
                      values?.endDate ? new Date(values?.endDate) : undefined
                    }
                    minTime={
                      responseStartDiffCurrentDate < 0
                        ? new Date(values.startDate)
                        : responseStartDiffCurrentDate === 0
                        ? currentDate
                        : responseStartDiffEndDate === 0
                        ? new Date(new Date().setHours(0, 0, 0, 0))
                        : undefined
                    }
                    maxTime={
                      responseStartDiffCurrentDate < 0
                        ? new Date(values.startDate)
                        : responseStartDiffEndDate === 0
                        ? new Date(new Date(values.endDate))
                        : responseStartDiffCurrentDate === 0
                        ? new Date(new Date().setHours(23, 59, 59, 999))
                        : undefined
                    }
                    onChange={value => {
                      if (value) {
                        const startDate =
                          value < currentDate
                            ? currentDate
                            : !values.endDate ||
                              value < new Date(values.endDate)
                            ? value
                            : new Date(values.endDate)
                        setFieldValue('startDate', startDate)
                      }
                    }}
                  />
                  <div className='text-[14px]'>ถึง</div>
                  <DateTimeInputField
                    className='w-[178px]'
                    name='endDate'
                    showTimeSelect
                    disabled={values.dateOption !== DateOption.RANGE}
                    minDate={
                      !values.startDate ||
                      new Date(values.startDate) < currentDate
                        ? currentDate
                        : new Date(values.startDate)
                    }
                    minTime={
                      responseEndDiffStartDate <= 0
                        ? new Date(values.startDate)
                        : undefined
                    }
                    maxTime={
                      responseEndDiffStartDate <= 0
                        ? new Date(
                            new Date(values.startDate).setHours(23, 59, 59, 999)
                          )
                        : undefined
                    }
                    onChange={value => {
                      if (value) {
                        const endDate =
                          value < new Date(values.startDate)
                            ? new Date(values.startDate)
                            : value
                        setFieldValue('endDate', endDate)
                      }
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-y-4 mt-6'>
                <div className='text-[18px] font-bold text-secondary'>
                  เลือกประเภท Badge
                </div>
                <div className='flex gap-x-6'>
                  <div className='flex flex-col gap-y-4'>
                    <SelectField
                      name='categoryOption'
                      className='w-[300px]'
                      placeholder='เลือกประเภท Badge'
                      options={categoryOption}
                    />
                    <Button
                      className='text-white bg-primary w-[300px] rounded-lg'
                      type='button'
                      disabled={!values.categoryOption}
                      onClick={() => {
                        badgeCategoryModal.show({
                          type: values.categoryOption,
                          initialValues: {
                            badgeCategoryId: values.badgeCategoryId,
                          },
                          onSubmit: ({
                            badgeCategoryId,
                            badgeCategoryImage,
                            badgeCategoryName,
                          }: BadgeCategoryModalFormType) => {
                            setFieldValue('badgeCategoryId', badgeCategoryId)
                            setPreviewName(badgeCategoryName ?? '')
                            setPreviewImage(badgeCategoryImage ?? '')
                          },
                        })
                      }}
                    >
                      เลือก Badge
                    </Button>
                  </div>
                  <div className='w-[200px] h-[286px] relative'>
                    <img
                      className='w-full h-full rounded-lg object-cover'
                      src='/images/avatar.png'
                      alt='cover'
                    />
                    {previewImage && (
                      <img
                        className='w-10 h-10 absolute right-3 top-0 rounded-b-lg'
                        src={previewImage}
                        alt='badge'
                      />
                    )}
                  </div>
                  {previewImage && previewName && (
                    <div className='shadow'>
                      <div className='flex flex-col gap-y-4 w-[300px] p-4'>
                        <span className='text-base font-bold text-secondary'>
                          Badge ที่เลือก:
                        </span>
                        <div className='flex flex-col justify-start gap-y-[10px]'>
                          <Box className='p-0 w-fit mx-auto'>
                            <img
                              className='w-[150px] h-[150px] aspect-square rounded-[8px]'
                              alt=''
                              src={previewImage}
                            />
                          </Box>
                          <div className='border-b-[1px] font-bold text-secondary pb-[10px] border-[#eee] break-all'>
                            {previewName}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Box>
            <div className='flex gap-x-[20px] font-mitr justify-center items-center text-[14px] font-medium mt-[30px]'>
              <Button
                type='button'
                className='w-[100px] border-[1px] border-secondary-100 text-secondary-100'
                onClick={() => navigate({ pathname: '/badge' })}
              >
                ยกเลิก
              </Button>
              <Button
                className='text-white bg-primary w-[100px]'
                type='submit'
                disabled={!dirty || !schema.isValidSync(values) || isSubmitting}
              >
                บันทึก
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
