import { withPermissionGuard } from '@libs/permission'
import { BadgeManagementPage } from './BadgeManagementPage'
import { withBadgeManagementPage } from './withBadgeManagementPage'

const ConnectedBadgeManagementPage = withPermissionGuard(
  withBadgeManagementPage(BadgeManagementPage),
  'badgeSetting',
  'view'
)

export { ConnectedBadgeManagementPage as BadgeManagementPage }
