import cn from 'classnames'
import { FieldArray } from 'formik'
import { ToggleField } from '@components/forms/ToggleField'
import { Checkbox } from '@components/Checkbox'
import { initialValues } from '@constants/form/AddNewRole/initialValues'
import { FeatureRoleListProps } from './interface'

const featureOptions = {
  dashboard: 'Dashboard',
  sale: 'Sale',
  homePageManagement: 'Home page Management',
  menuManagement: 'MenuManagement',
  chapterBook: 'Chapter Book',
  promotionSetting: 'Promotion Setting',
  badgeSetting: 'Badge',
  newsPromotion: 'News & Promotion',
  user: 'User',
  userMission: 'User Mission',
  coin: 'Coin',
  searchManagement: 'Search Management',
  admin: 'Admin',
}

const featureDetailOptions = [
  { value: 'view', label: 'View' },
  { value: 'create', label: 'Create' },
  { value: 'edit', label: 'Edit' },
  { value: 'delete', label: 'Delete' },
  { value: 'download', label: 'Download' },
  { value: 'approve', label: 'Approve' },
]

export function FeatureRoleList({
  permissions,
  handleSelectChange,
}: FeatureRoleListProps) {
  return (
    <FieldArray
      name='permissions'
      render={arrayHelpers => (
        <div className='flex flex-col border-t-[1px] mt-[15px] font-light text-secondary text-[14px]'>
          {permissions.map((permissioin, index) => (
            <div
              key={index}
              className='grid grid-cols-[190px_1fr] py-[20px] border-b-[1px]'
            >
              <div className='font-mitr font-light text-secondary text-[14px]'>
                {featureOptions[permissioin.name]}
              </div>
              <div className='flex  justify-end'>
                <ToggleField
                  name={`permissions.${index}.status`}
                  className='mr-[90px]'
                  onChange={e => {
                    if (e.target.checked) {
                      arrayHelpers.replace(index, {
                        ...permissions[index],
                        status: e.target.checked,
                        actions: initialValues.permissions[index].actions,
                      })
                    } else {
                      arrayHelpers.replace(index, {
                        ...permissions[index],
                        status: e.target.checked,
                        actions: [],
                      })
                    }
                  }}
                />
                <div className='flex gap-x-[40px]'>
                  {featureDetailOptions.map((option, i) => (
                    <>
                      {permissioin.name !== 'coin' &&
                      permissioin.name !== 'userMission' &&
                      permissioin.name !== 'promotionSetting' &&
                      option.value === 'approve' ? (
                        <p className='w-[83px] text-right text-secondary-100'>
                          Approve
                        </p>
                      ) : (
                        <Checkbox
                          key={i}
                          defaultChecked={permissions[index].actions.some(
                            element => element === option.value
                          )}
                          label={
                            <span
                              className={cn({
                                'text-secondary-100':
                                  !permissions[index].status,
                              })}
                            >
                              {option.label}
                            </span>
                          }
                          onClick={() =>
                            handleSelectChange(
                              arrayHelpers,
                              index,
                              option.value
                            )
                          }
                          disabled={!permissions[index].status}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    />
  )
}
