import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { useClient } from '@hooks/useClient'
import type { BadgeManagementFormType } from '@models/badge/BadgeManagementFormType'
import type { BadgeManagementAddPageProps } from './interface'
import { BookBadgeCategoryTypeEnum } from '../BadgeListPage/interface'

export function withBadgeManagementAddPage(
  Component: React.FC<BadgeManagementAddPageProps>
) {
  function WithBadgeManagementAddPage() {
    const client = useClient()
    const navigate = useNavigate()
    const { type } = useParams()
    const [tab, setTab] = useState<BookBadgeCategoryTypeEnum>(
      (type?.toUpperCase() ??
        BookBadgeCategoryTypeEnum.EXCLUSIVE) as BookBadgeCategoryTypeEnum
    )
    const initialValues = {
      name: '',
      upload: {
        url: '',
        blob: undefined,
      },
    }

    const { mutate: createBadge } = useMutation(
      async (form: BadgeManagementFormType) => {
        const id = await client!.badgeClient.createBookBadgeCategory({
          name: form.name,
          bookBadgeCategoryType: tab,
        })

        if (!id) {
          throw new Error('Failed to create badge')
        }

        return client!.badgeClient.uploadBookBadgeCategoryImage({
          id,
          name: form.name,
          upload: form.upload,
        })
      },
      {
        onSuccess: () => {
          navigate({
            pathname: `/badge/badge-management/${tab.toLowerCase()}`,
          })
        },
      }
    )

    useEffect(() => {
      const formattedType =
        type?.toUpperCase() ?? ('' as BookBadgeCategoryTypeEnum)
      if (
        Object.values(BookBadgeCategoryTypeEnum).includes(
          formattedType as BookBadgeCategoryTypeEnum
        )
      ) {
        setTab(formattedType as BookBadgeCategoryTypeEnum)
      } else {
        setTab(BookBadgeCategoryTypeEnum.EXCLUSIVE)
      }

      /* eslint-disable react-hooks/exhaustive-deps */
    }, [])

    async function onSubmit(form: BadgeManagementFormType) {
      createBadge(form)
    }

    const newProps = {
      initialValues,
      onSubmit,
      tab,
    }
    return <Component {...newProps} />
  }

  return WithBadgeManagementAddPage
}
