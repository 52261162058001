import React, { useRef } from 'react'
import { useField } from 'formik'

import type { ImageType } from '@interfaces/ImageType'
import type { UploadBadgeFieldProps } from './interface'

export function withUploadBadgeField(
  Component: React.FC<UploadBadgeFieldProps>
) {
  function WithUploadStickerField() {
    const [{ value }, { error, touched }, { setValue }] =
      useField<ImageType>('upload')
    const inputRef = useRef<HTMLInputElement>(null)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        const img = new Image()
        const url = URL.createObjectURL(files[0])
        img.src = url
        img.onload = () => {
          setValue({
            url,
            blob: files[0],
          })
          URL.revokeObjectURL(url)
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: value?.url,
      isError: !!error && touched,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadStickerField
}
