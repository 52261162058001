import React, { useRef } from 'react'
import { useFormikContext } from 'formik'

import type { StickerManagementFormType } from '@models/sticker/StickerManagementFormType'
import type { UploadBadgeButtonFieldProps } from './interface'

export function withUploadBadgeButtonField(
  Component: React.FC<UploadBadgeButtonFieldProps>
) {
  function WithUploadBadgeButtonField() {
    const inputRef = useRef<HTMLInputElement>(null)
    const { values, setFieldValue } =
      useFormikContext<StickerManagementFormType>()

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const { files } = event.target

      if (files) {
        const img = new Image()
        const url = URL.createObjectURL(files[0])
        img.src = url
        img.onload = () => {
          setFieldValue('upload', {
            url: URL.createObjectURL(files[0]),
            blob: files[0],
          })
          URL.revokeObjectURL(url)
        }
      }
    }

    function showUploadDialog() {
      const event = inputRef.current
      if (event) {
        event.click()
      }
    }

    const componentProps = {
      inputRef,
      src: values?.upload?.url,
      onChange: handleChange,
      showUploadDialog,
    }

    return <Component {...componentProps} />
  }

  return WithUploadBadgeButtonField
}
