import { Container } from '@components/Container'
import { BadgeListTable } from '@features/badgeSetting/components/BadgeListTable'
import { SelectButtonLine } from '@components/SelectButtonLine'
import { PatchCheckIcon, PromotionIcon } from '@components/Icons'
import { BadgeSearchForm } from '@features/badgeSetting/components/BadgeSearchForm'

import { BookBadgeCategoryTypeEnum, type BadgeListPageProps } from './interface'

export function BadgeListPage({
  isLoading,
  tab,
  data,
  total,
  page,
  perpage,
  tabOption,
  handleTabChange,
  initialValues,
  navigate,
  handlePageChange,
  handleSubmitSearch,
  handleResetSearch,
  handleRemoveBadge,
  refetch,
}: BadgeListPageProps) {
  return (
    <Container>
      <section className='flex items-center justify-between font-mitr'>
        <Container.Title showBackBtn={false}>Badge</Container.Title>
        <div className='flex gap-x-5'>
          <Container.MenuButton
            value='Badge Library'
            icon={<PromotionIcon />}
            onClick={() =>
              navigate(
                `/badge/badge-management/${
                  tab === BookBadgeCategoryTypeEnum.ALL
                    ? BookBadgeCategoryTypeEnum.EXCLUSIVE.toLowerCase()
                    : tab.toLowerCase()
                }`
              )
            }
          />
          <Container.MenuButton
            value='สร้างป้าย (Badge)'
            icon={<PatchCheckIcon />}
            onClick={() => navigate('/badge/create')}
          />
        </div>
      </section>

      <BadgeSearchForm
        initialValues={initialValues}
        handleSubmit={handleSubmitSearch}
        handleResetSearch={handleResetSearch}
      />

      <SelectButtonLine
        options={tabOption}
        value={tab}
        className='mt-[20px] mb-[30px]'
        onChange={handleTabChange}
      />

      <BadgeListTable
        page={page}
        perpage={perpage}
        activeTab={tab}
        data={data}
        total={total}
        refetch={refetch}
        pageChange={handlePageChange}
        isLoading={isLoading}
        handleRemoveBadge={handleRemoveBadge}
      />
    </Container>
  )
}
