import { withPermissionGuard } from '@libs/permission'
import { BadgeListPage } from './BadgeListPage'
import { withBadgeListPage } from './withBadgeListPage'

const Connected = withPermissionGuard(
  withBadgeListPage(BadgeListPage),
  'badgeSetting',
  'view'
)

export { Connected as BadgeSettingPage }
