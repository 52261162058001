import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { plainToInstance } from 'class-transformer'
import { useClient } from '@hooks/useClient'
import { useAlert } from '@hooks/useAlert'
import { BookBadgeFormType } from '@models/badge/BookBadgeFormType'
import { getErrorMessage } from '@libs/utils'

import type { BookBadgeAddPageProps } from './interface'

export function withBookBadgeAddPage(
  Component: React.FC<BookBadgeAddPageProps>
) {
  function WithBookBadgeAddPage() {
    const client = useClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const initialValues: BookBadgeFormType = {
      bookIds: [],
      bookIdsInput: '',
      bookIdInputError: '',
      bookIdFileError: '',
      badgeCategoryId: 0,
      dateOption: undefined,
      categoryOption: undefined,
      startDate: '',
      endDate: '',
    }

    const { mutate: create, isLoading } = useMutation(
      (body: BookBadgeFormType) => client!.badgeClient.createBookBadge(body),
      {
        onSuccess: () => {
          alert.success('ผูก badge กับเรื่องสำเร็จแล้ว')
          navigate({
            pathname: '/badge',
          })
        },
        onError: error => {
          alert.error(getErrorMessage(error))
        },
      }
    )

    function handleClickBack() {
      navigate({
        pathname: '/badge',
      })
    }

    function handleSubmit(values: BookBadgeFormType) {
      create(plainToInstance(BookBadgeFormType, values))
    }

    const props = {
      initialValues,
      handleSubmit,
      handleClickBack,
      isSubmitting: isLoading,
    }

    return <Component {...props} />
  }

  return WithBookBadgeAddPage
}
