import { gql } from 'graphql-request'

export const GET_BOOK_BADGE_CATEGORIES = gql`
  query GetBookBadgeCategories(
    $limitPerPage: Int
    $page: Int
    $bookBadgeType: BookBadgeCategoryTypeEnum!
  ) {
    getBookBadgeCategories(
      limitPerPage: $limitPerPage
      page: $page
      bookBadgeType: $bookBadgeType
    ) {
      data {
        id
        name
        bookBadgeType
        createdAt
        updatedAt
        deletedAt
        bookBadgeImageUrl
      }
      total
    }
  }
`
