import { useNavigate } from 'react-router-dom'
import cn from 'classnames'

import { ArrowLeftIcon } from '@components/Icons'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export function Container({ children, className }: ContainerProps) {
  return (
    <div
      className={cn(
        'px-[50px] pt-[30px] pb-[80px] mx-auto min-w-[1129px] max-w-[1129px]',
        className
      )}
    >
      {children}
    </div>
  )
}

interface ContainerProps extends FunctionComponentType {
  children: React.ReactNode
  className?: string
}
interface ContainerTitleProps {
  className?: string
  children: React.ReactNode
  showBackBtn: boolean
  onClickBackBtn?: () => void
}

Container.Title = function ContainerTitle({
  className,
  children,
  showBackBtn,
  onClickBackBtn,
}: ContainerTitleProps) {
  const navigate = useNavigate()

  return (
    <div className='flex items-center gap-x-[20px] mb-[30px]'>
      {showBackBtn && (
        <button
          className='text-white bg-secondary px-[17px] py-[8px] rounded-[20px]'
          type='button'
          onClick={() => {
            if (onClickBackBtn) {
              onClickBackBtn()
            } else {
              navigate(-1)
            }
          }}
        >
          <ArrowLeftIcon width='24' height='24' />
        </button>
      )}
      <div
        className={cn(
          'text-[24px] text-secondary font-medium font-mitr flex-1',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}

Container.MenuButton = function MenuButton({
  value,
  icon,
  ...props
}: {
  value: string
  icon: JSX.Element
  onClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <button
      type='button'
      className='flex items-center gap-x-[10px] bg-secondary text-white text-sm max-w-[250px] h-10 rounded-full py-2 px-5'
      {...props}
    >
      {icon}
      {value}
    </button>
  )
}

Container.Hr = function Hr({ className }: FunctionComponentType) {
  return <div className={cn('border-b border-gray my-[20px]', className)} />
}
