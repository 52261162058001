import { GraphQLClient } from 'graphql-request'
import { plainToInstance } from 'class-transformer'
import { BookBadgeCategoryTypeEnum } from '@features/badgeSetting/pages/BadgeListPage/interface'
import {
  BadgeCategoryRequestResponse,
  BadgeRequestResponse,
} from '@models/badge/BadgeRequestResponse'
import { GET_BOOK_BADGES } from '@client/collections/Badge/schemas/getBookBadges'
import { REMOVE_BOOK_BADGE } from '@client/collections/Badge/schemas/removeBookBadge'
import { REMOVE_BOOK_BADGE_CATEGORY } from '@client/collections/Badge/schemas/removeBookBadgeCategory'
import { UPLOAD_BOOK_BADGE_CATEGORY_IMAGE } from '@client/collections/Badge/schemas/uploadBookBadgeCategoryImage'
import { UPDATE_BOOK_BADGE_CATEGORY } from '@client/collections/Badge/schemas/updateBookBadgeCategory'
import { GET_BOOK_BADGE_CATEGORIES } from '@client/collections/Badge/schemas/getBookBadgeCategories'
import { CREATE_BOOK_BADGE_CATEGORY } from '@client/collections/Badge/schemas/createBookBadgeCategory'
import { CREATE_BOOK_BADGE } from '@client/collections/Badge/schemas/createBookBadge'
import { BadgeManagementFormType } from '@models/badge/BadgeManagementFormType'
import { BookBadgeFormType, DateOption } from '@models/badge/BookBadgeFormType'

export class BadgeClient {
  constructor(private client: GraphQLClient) {}

  async getBookBadges({
    limitPerPage,
    page,
    type,
    search,
  }: {
    limitPerPage: number
    page: number
    search?: string
    type: BookBadgeCategoryTypeEnum
  }): Promise<BadgeRequestResponse> {
    const { getBookBadges } = await this.client.request(GET_BOOK_BADGES, {
      limitPerPage,
      page,
      search,
      badgeCategoryType:
        type === BookBadgeCategoryTypeEnum.ALL ? undefined : type,
    })
    return plainToInstance(BadgeRequestResponse, getBookBadges)
  }

  async removeBookBadge({ badgeId }: { badgeId: number }) {
    await this.client.request(REMOVE_BOOK_BADGE, {
      removeBadgeInput: { id: badgeId },
    })
  }

  async getBookBadgeCategories({
    limitPerPage,
    page,
    type,
  }: {
    limitPerPage: number
    page: number
    type: BookBadgeCategoryTypeEnum
  }): Promise<BadgeCategoryRequestResponse> {
    const { getBookBadgeCategories } = await this.client.request(
      GET_BOOK_BADGE_CATEGORIES,
      {
        limitPerPage,
        page,
        bookBadgeType: type,
      }
    )

    return plainToInstance(BadgeCategoryRequestResponse, getBookBadgeCategories)
  }

  async createBookBadge(body: BookBadgeFormType) {
    await this.client.request(CREATE_BOOK_BADGE, {
      createBadgeInput: {
        bookIds: body.bookIds.length ? body.bookIds : body.bookIdsInput,
        badgeCategoryId: body.badgeCategoryId,
        ...(body.dateOption === DateOption.FOREVER
          ? { startDate: new Date() }
          : {
              startDate: body.startDate,
              endDate: body.endDate,
            }),
      },
    })
  }

  async createBookBadgeCategory({
    name,
    bookBadgeCategoryType,
  }: {
    name: string
    bookBadgeCategoryType: BookBadgeCategoryTypeEnum
  }): Promise<number> {
    const { createBookBadgeCategory } = await this.client.request(
      CREATE_BOOK_BADGE_CATEGORY,
      {
        createBadgeCategoryInput: { name, bookBadgeCategoryType },
      }
    )

    return createBookBadgeCategory?.id ?? 0
  }

  async removeBookBadgeCategory(input: { id: number }) {
    await this.client.request(REMOVE_BOOK_BADGE_CATEGORY, {
      removeBadgeCategoryInput: input,
    })
  }

  async updateBadgeCategory(form: BadgeManagementFormType): Promise<void> {
    if (form.upload.blob) {
      await this.uploadBookBadgeCategoryImage({
        id: form.id,
        name: form.name,
        upload: form.upload,
      })
    }

    await this.client.request(UPDATE_BOOK_BADGE_CATEGORY, {
      updateBadgeCategoryInput: {
        id: form.id,
        name: form.name,
      },
    })
  }

  async uploadBookBadgeCategoryImage({
    id,
    name,
    upload,
  }: BadgeManagementFormType): Promise<string> {
    const { uploadBookBadgeCategoryImage } = await this.client.request(
      UPLOAD_BOOK_BADGE_CATEGORY_IMAGE,
      {
        name,
        badgeCategoryId: id,
        file: upload.blob,
      }
    )

    return uploadBookBadgeCategoryImage?.filePath ?? ''
  }
}
