import { gql } from 'graphql-request'

export const CREATE_BOOK_BADGE_CATEGORY = gql`
  mutation CreateBookBadgeCategory(
    $createBadgeCategoryInput: CreateBadgeCategoryInput!
  ) {
    createBookBadgeCategory(
      createBadgeCategoryInput: $createBadgeCategoryInput
    ) {
      id
    }
  }
`
