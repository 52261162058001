import React, { useEffect, useState } from 'react'
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { QueryKey, useQuery } from 'react-query'
import { useAlert } from '@hooks/useAlert'
import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { useClient } from '@hooks/useClient'
import { BadgeSearchFormType } from '@models/badge/BadgeSearchFormType'
import { usePagination } from '@hooks/usePagination'
import { getErrorMessage } from '@libs/utils'
import {
  BookBadgeCategoryTypeEnum,
  bookBadgeCateoryName,
  type BadgeListPageProps,
} from './interface'

const tabOption = [
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.ALL],
    value: BookBadgeCategoryTypeEnum.ALL,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.EXCLUSIVE],
    value: BookBadgeCategoryTypeEnum.EXCLUSIVE,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.MOVIES_SERIES],
    value: BookBadgeCategoryTypeEnum.MOVIES_SERIES,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.BESTSELLER],
    value: BookBadgeCategoryTypeEnum.BESTSELLER,
  },
  {
    text: bookBadgeCateoryName[BookBadgeCategoryTypeEnum.AWARD],
    value: BookBadgeCategoryTypeEnum.AWARD,
  },
]
const tabPath = {
  [BookBadgeCategoryTypeEnum.ALL]: '/badge',
  [BookBadgeCategoryTypeEnum.EXCLUSIVE]: `/badge/${BookBadgeCategoryTypeEnum.EXCLUSIVE.toLowerCase()}`,
  [BookBadgeCategoryTypeEnum.MOVIES_SERIES]: `/badge/${BookBadgeCategoryTypeEnum.MOVIES_SERIES.toLowerCase()}`,
  [BookBadgeCategoryTypeEnum.BESTSELLER]: `/badge/${BookBadgeCategoryTypeEnum.BESTSELLER.toLowerCase()}`,
  [BookBadgeCategoryTypeEnum.AWARD]: `/badge/${BookBadgeCategoryTypeEnum.AWARD.toLowerCase()}`,
}

export function withBadgeListPage(Component: React.FC<BadgeListPageProps>) {
  function Hoc() {
    const client = useClient()
    const alert = useAlert()
    const confirmModal = useModal({ modal: 'confirm' })
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const { type: typeParams } = useParams()
    const type = (typeParams?.toUpperCase() ??
      BookBadgeCategoryTypeEnum.ALL) as BookBadgeCategoryTypeEnum
    const text = params.get('text') || ''
    const [tab, setTab] = useState<BookBadgeCategoryTypeEnum>(
      type as BookBadgeCategoryTypeEnum
    )
    const [searchParams, setSearchParams] = useState<BadgeSearchFormType>({
      text,
    })
    const { page, perpage, pageChange } = usePagination({
      page: Number(params.get('page')),
    })

    const queryKey: QueryKey = [
      'get-book-badges',
      searchParams,
      page,
      perpage,
      tab,
    ]

    const {
      data: badgeList,
      isLoading,
      refetch,
    } = useQuery(queryKey, () =>
      client?.badgeClient.getBookBadges({
        limitPerPage: 10,
        page,
        search: searchParams.text,
        type: tab,
      })
    )

    useEffect(() => {
      const match = Object.entries(tabPath).find(
        ([, value]) => value === window.location.pathname
      )
      if (match) {
        setTab(match[0] as BookBadgeCategoryTypeEnum)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleRemoveBadge(bookId: number, badgeId: number) {
      const book =
        badgeList?.data.find(item => item.bookId === bookId) ?? undefined
      const bookBadge =
        book?.bookBadges?.find(item => item.bookBadgeId === badgeId) ??
        undefined

      if (!book || !bookBadge) {
        alert.error('ไม่พบข้อมูล Badge นี้')
        return
      }

      confirmModal.show({
        content: (
          <div className='w-[400px] mb-[20px] '>
            <p className='font-mitr text-center text-[24px] text-secondary font-medium '>
              ยืนยันการลบ Badge <br /> (
              {bookBadgeCateoryName[bookBadge.badgeCategoryType]}) ของเรื่อง{' '}
              <br />
              {book.bookTitle} หรือไม่?
            </p>
          </div>
        ),
        onConfirm: async () => {
          try {
            await client?.badgeClient.removeBookBadge({ badgeId })
            alert.success('ลบ Badge สำเร็จ')
          } catch (error) {
            alert.error(getErrorMessage(error))
          } finally {
            confirmModal.hide()
            refetch()
          }
        },
        onClose: () => {
          confirmModal.hide()
        },
      })
    }

    function handleSubmitSearch(value: BadgeSearchFormType) {
      pageChange(1)
      setSearchParams(prev => {
        const newSearchParams = {
          ...prev,
          text: value.text,
        }

        navigate({
          search: `?${createSearchParams({
            ...newSearchParams,
          })}`,
        })
        return newSearchParams
      })
    }

    function handleResetSearch() {
      pageChange(1)
      setSearchParams({
        text: '',
      })

      navigate({})
    }

    function onActiveTabChange(value: BookBadgeCategoryTypeEnum) {
      pageChange(1)
      setTab(value)

      const query = new URLSearchParams()

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      navigate({
        pathname: tabPath[value],
        search: query.toString() ? `?${query.toString()}` : '',
      })
    }

    function handlePageChange(value: number) {
      pageChange(value)

      const query = new URLSearchParams()
      query.set('page', String(value))

      if (searchParams.text) {
        query.set('text', searchParams.text)
      }

      navigate({
        search: `?${query.toString()}`,
      })
    }

    const componentProps = {
      tabOption,
      searchParams,
      initialValues: searchParams,
      handleTabChange: onActiveTabChange,
      tab,
      page,
      perpage,
      queryKey,
      data: badgeList?.data ?? [],
      total: badgeList?.total ?? 0,
      isLoading,
      handlePageChange,
      navigate,
      handleSubmitSearch,
      handleResetSearch,
      handleRemoveBadge,
      refetch,
    }

    return <Component {...componentProps} />
  }

  return Hoc
}
